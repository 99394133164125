@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

/* vietnamese */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/josefinsans/v24/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMlhLzTs.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/josefinsans/v24/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMhhLzTs.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/josefinsans/v24/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMZhLw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.appoint_section_new {
  margin-top: 10px;
}

.appoint_section_new-rs {
  margin-top: 25px;
}

.appoint_section_new p {
  font-size: 16px !important;
}

.appoint_section_new-rs p {
  font-size: 20px !important;
  line-height: 2;
}

.appoint_section_new .form-head {
  font-size: 20px;
  margin-bottom: 25px;
}

.appoint_section_new-rs .form-head {
  font-size: 20px;
  margin-bottom: 25px;
}

.sgst_per p {
  text-align: left;
}

.appoinment_logo img {
  width: 100%;
  max-width: 175px;
}

.border_total_amount.text-right.ph_view {
  min-width: 34.5%;
}

.sgst_per {
  width: 32.5%;
  margin-left: auto;
}

/* dashboard css */
.inputStyle {
  border: 1px solid #ccc !important;
  padding: 8px 12px;
  height: auto;
  border-radius: 8px;
  color: #a5a5a5;
}

span.toRange {
  padding: 8px;
  font-weight: 500;
}

.shadow_data {
  width: 33%;
  padding: 2rem;
  box-shadow: 2px 4px 5px #ccc;
  border-radius: 10px;
  background: #fff;
}

.shadowPayments {
  box-shadow: 1px 3px 4px #ccc;
  width: 49%;
  padding: 10px;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 10px;
}

.shadowPayment {
  display: flex;
  padding: 10px;
  gap: 10px;
}




.logo.text_invoice img,
.com_logo {
  width: 300px;
  object-fit: contain !important;
  padding: 25px;
}

.app-wraper {
  background-color: #fafafa;
  overflow-x: hidden;
}

.nav-loading {
  width: 80px;
  position: relative;
  left: 8px;
}

.desc_data.PDF_Desc {
  display: flex;
}

section.amount_task {
  display: flex;
  justify-content: space-between;
}

section.amount_task p {
  width: 100% !important;
}

input#chatbutton {
  position: relative;
  top: 5px;
}

.task_name {
  width: 310px !important;
  max-width: 310px !important;
  min-width: 310px !important;
}

.nav-loading-right {
  width: 64px;
  height: 40px;
  right: 0px;
}

.appoinment_banner {
  height: 78px;
  margin-top: 5rem;
}

.primary-self-text {
  color: rgb(0, 102, 255) !important;
}

.primary-background-color {
  background-color: rgb(0, 102, 255) !important;
}

.task_desc {
  display: flex !important;
  min-width: 100% !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 3px;
  margin-bottom: 5px;
  align-items: center;
  text-transform: capitalize;
}

.deta_combine p {
  width: 43%;
  font-size: 16px;
}

img.w-full.header_invoice {
  height: 180px;
  object-fit: cover;
  margin-bottom: 5px;
}

.right_amount {
  width: 100% !important;
  max-width: 100% !important;
  padding-bottom: 5px;
}

.desc_data.PDF_Desc p {
  width: 100% !important;

}

.task_desc p {
  width: 100%;
}

.blank_ph p {
  font-weight: 500;
}

.task_combine {
  width: 100% !important;
  max-width: 100% !important;
  display: flex !important;
}

.combine_div .deta_combine {
  width: 100% !important;
  max-width: 100% !important;
  display: flex !important;
  /* border-bottom: 1px solid #ccc; */
  padding: 1px 0px;
  line-height: 2;
}

.combine_div {
  min-width: 78% !important;
}

.text-default-color {
  color: #777;
}

.side-link.active {
  color: rgb(0, 102, 255);
  display: flex;
  background-color: rgba(0, 102, 255, 0.1);
  transition-delay: 0.2s;
  transition: all 0.3 linear;
}

header {
  min-height: 80px;
}

.skeleton-input-radius {
  border-radius: 0.75rem !important;
}

.skeleton-image {
  width: 3.2rem !important;
  height: 3.2rem !important;
}

/* Responsive Css */
/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #777;
  }

  .responsiveTable tbody tr {
    /* border: 1px solid #000; */
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}

br {
  height: 0 !important;
  display: none;
  opacity: 0;
  visibility: hidden;
}

.inline-block {
  display: inline-block !important;
}

.modal-container {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.fixed-body-scroll {
  /* position: fixed; */
}

@page {
  size: landscape;
}

.title-table {
  color: #f6f6f6;
}

.project-ch {
  border-bottom: 1px solid #cccc;
  margin-bottom: 2rem;
}

.logo {
  /* text-align: center; */
  /* text-align: -webkit-center; */
}

.logo.text_invoice {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -4rem;
}

.form-num {
  margin-left: 26rem;
}

.check-pro {
  display: flex;
  gap: 1rem;
  font-size: 15px;
  font-weight: 600;
}

.invoice {
  width: 62%;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.invoice_section_new {
  padding: 20px;
  margin-top: 1rem;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background: #ffffffe8;
  z-index: 99;
  position: relative;
  height: 910px;
}

img.logo_invoice_overlap {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.desc_data.PDF_Desc p {
  font-size: 16px;
  font-weight: 500;
  /* line-height: 2; */
}

.status_ph {
  min-width: 10% !important;
  max-width: 10% !important;
}

b.space_desc {
  min-width: 30% !important;
}

.invoice-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

.invoice-body .invoice-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.invoice-body .invoice-details span {
  font-weight: bold;
}

.thead b {
  min-width: 23%;
  width: 13%;
  max-width: 23%;
  display: inline-block;
  color: #042da0;
  font-size: 18px;
}

.Invoice_data div,
.total_amount div {
  min-width: 20%;
  width: 25%;
  max-width: 33%;
  display: inline-block;
}

.total_amount,
.sgst_per {
  margin-top: 0rem;
}

.table_invoce .thead {
  /* border-top: 1px solid #000; */
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.Invoice_data {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #ccc; */
}

.border_total_amount.text-right {
  border-top: 1px solid;
  /* border-bottom: 1px solid; */
  margin-top: 10px;
}

h3.word_amount {
  font-size: 20px;
  margin-bottom: 2.5rem;
  margin-top: 1rem;  
  font-weight: 700;
}

.bank_data div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 32px;
}

.bank_data span {
  font-weight: 700;
  min-width: 320px;
}

.invoice-details_data span {
  min-width: 250px;
}

input.transprent_gst {
  background: transparent;
  height: 30px !important;
}

/* .invoice-body .invoice-details span {
  font-weight: normal;
} */

.invoice-body .invoice-details .total-value {
  grid-column: span 2;
  font-weight: bold;
  font-size: 24px;
}

input {
  border: none;
  height: 22px;
}


.table-in {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

.table-in th,
td {
  padding: 12px;
  text-align: left !important;
}

.table-in thead tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.table-in td:nth-child(4) {
  border-bottom: 2px solid black;
}

.form-head {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.bill-head {
  border-bottom: 1px solid black;
  width: 49%;
  padding-bottom: 10px;
  color: #042da0;
  font-size: 18px;
  /* text-align: center; */
}

.desc_data {
  min-width: 50% !important;
}

.tax-in {
  text-align: center;
  font-weight: 700;
  color: #f17a61;
  font-size: 18px;
}

/* .main-footer {
  padding: 24px 0 24px;
  background: #f5aa64;
} */
.main-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.main-footer-rs {
  position: absolute;
  bottom: 0;
  /* top: 0px; */
  width: 100%;
  /* background: #042da0; */
  background-image: url("../public/invoice_banner_appoinment.png");
  background-size:cover;
  background-repeat: no-repeat;
  background-position:0 0;
  z-index: 999;
}

.footer {
  display: flex;
  /* background: #6866ed; */
  color: white;
  font-size: 14px;
  width: auto;
  align-items: center;
  justify-content: space-between;
}

.middle {
  padding: 17px 12px;
  font-size: 12px;
}

.address {
  padding: 7px;
}

.icon-text {
  display: flex;
  gap: 7px;
}

.icon-text-2 {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
}

p {
  margin-bottom: 0rem !important;
}

.icon-text span {
  border: 2px solid white;
  padding: 10px;
  border-radius: 10px;
}

.icon-text-2 span {
  border: 2px solid white;
  padding: 10px;
  border-radius: 10px;
}

.paid-row {
  background-color: #C6F6D5;
}

.unpaid-row {
  background-color: #FECDD3;
}

.invoice-details_data div {
  display: flex;
  justify-content: space-between;
  line-height: 1.8;
}

.invoice-details_data span {
  font-weight: 500;
  font-size: 16px;
}

.status_ph p {
  /* background: #047dd652; */
  /* width: fit-content; */
  /* padding: 1px 20px; */
  /* border: 1px solid #052fa5; */
  /* border-radius: 40px; */
  margin-top: 10px;
  /* color: #042da0; */
  font-weight: 600;
  line-height: 1.5;
  /* height: 28px; */
  font-size: 16px;
}

.border_total_amount.text-right.ph_view b {
  font-style: italic;
  font-size: 18px;
}

.invoice-details_data div label {
  font-size: 16px;

}

.invoice-detail p {
  font-size: 16px;
  line-height: 1.8;
}

.description-data p {
  font-size: 16px;
}

.text-right p {
  font-size: 16px;
  font-weight: 500;
}

.bank_data div label {
  font-size: 16px;
}

.flexer {
  display: flex;
  gap: 5rem;
}

.adjust-width {
  max-width: 400px;
  width: 100%;
}

.table-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.salary-slip {
  margin-bottom: 20px;
  background: #ffffffde;
  z-index: 9;
  position: relative;
}

.salary-slip h2 {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.salary-slip table {
  border-collapse: collapse;
}

.salary-slip th,
.salary-slip td {
  padding: 5px;
  border-right: 1px solid #ccc !important;
  border-left: 1px solid #ccc;
}

.salary-slip th {
  background-color: #eee;
  font-weight: normal;
}

.salary-slip tr:nth-child(even) {
  background-color: #00468114;
}

.salary-slip .total {
  font-weight: bold;
  border-bottom: 2px solid;
}

.hide-checkbox:checked+b {
  display: none;
}

.appoint_section_new {
  padding: 20px;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background: #ffffffe8;
  z-index: 99;
  position: relative;
  height: auto;
}

.appoint_section_new-rs {
  padding: 20px;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  background: #ffffffe8;
  z-index: 99;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 1164px;
  /* overflow: auto; */
}

.salary-slip .net-salary {
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
  border-bottom: 2px solid;
}

.ck-editor__editable_inline {
  min-height: 300px;
}


.date-range-picker {
  display: flex;
}

.salary-slip .amount {
  text-align: right;
}

.salary-slip .section-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.salary-slip .section-header {
  /* background-color: #004681; */
  font-weight: bold;
  color: #004681;
}

.width_add {
  width: 40%;
}

.table-row {
  float: right;
  font-weight: 700;
  position: relative;
  right: 8px;
}

.bold_data {
  font-weight: 700 !important;

}

.company-head {
  text-align: center;
  margin-top: 2rem;
  font-size: 15px;
  font-weight: 600;
}

.table-body tr td {
  font-weight: 600;
  line-height: 2.5;
}

.sal-advice {
  border-bottom: 1px solid #ccc;
  height: 25px;
  border-right: unset !important;
}

.head-line {
  border-bottom: 3px solid;
  height: 25px;
  border-right: unset !important;

}

.bot-border {
  border-bottom: 1px solid #ccc;
}

td.sal-advice {
  border-top: 1px solid #ccc;
}

tbody.table-body {
  box-shadow: 0px 9px 20px #cccccc85;
}

.sal-advice span {
  display: flex;
  justify-content: space-evenly;
}

.wages_header h3 {
  font-size: 30px;
  font-weight: 700;
  color: #fd7c50;
}

.wages_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  background: #ebf0f5;
  border-bottom: 5px solid #004681;
  box-shadow: 0px 5px 10px #cccccc6b;
}

.company-name {
  /* border: 1px solid #ccc;  */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.admin-sign {
  display: flex;
  justify-content: center;
  gap: 9px;
}

.image-adjust {
  width: 18%;
  height: 67px;
  margin-top: 18px;
}

.desc_hide {
  display: none;
}

.appoint_section_new p {
  font-size: 14px;
  line-height: 22px;
}

.appoint_section_new-rs p {
  font-size: 14px;
}

.appoinment_logo img {
  display: block;
  margin: auto;
  /* padding-top: 15px; */
  /* padding-bottom: 17px; */
}

.flex_date_tenure {
  display: flex;
  gap: 10px;
}

.appoinment_logo {
  border-bottom: 1px solid #4c4c4c;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px #ccc;
  padding: 20px;
}

.appoint_section_new h3 {
  text-align: center;
}

.appoint_section_new-rs h3 {
  text-align: center;
}

@media screen and (max-width: 1550px) {
  .invoice {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .invoice {
    width: 100%;
  }

  .bank_data div label {
    font-size: 14px;
  }

  .bank_data span {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .invoice_section_new {
    height: auto;
  }

  .form-head b {
    display: block;
  }

  .bank_data span {
    font-size: 12px;
  }

  .deta_combine p {
    width: 40%;
    font-size: 12px;
    line-height: 1.4;
  }

  .desc_data.PDF_Desc p {
    font-size: 12px;
  }

  .status_ph p {
    font-size: 12px;
    margin-top: 2px;
  }

  .border_total_amount.text-right.ph_view b {
    font-size: 14px;
  }

  .ph_view p {
    font-size: 14px !important;
  }

  .appoint_section_new p {
    font-size: 12px;
  }

  .appoint_section_new-rs p {
    font-size: 12px;
  }

  img.logo_invoice_overlap {
    object-fit: contain;
    height: 250px;
  }

  .main-footer {
    position: relative;
    bottom: 0;
    top: 20px;
    width: 100%;
    background: #042da0;
  }
  .main-footer-rs {
    position: relative;
    bottom: 0;
    top: 20px;
    width: 100%;
    background: #042da0;
    height: 100%;
  }

  .appoint_section_new {
    padding: 10px;
  }

  .appoint_section_new-rs {
    padding: 10px;
  }

  .ph_none_banner {
    display: none;
  }

  .footer {
    display: block;
  }

  img.w-full.header_invoice {
    display: none;
  }

  .logo.text_invoice {
    display: flex;
    justify-content: center;
    position: inherit;
    left: unset;
    transform: unset;
    margin-top: 0rem;
  }

  .invoice_section_new {
    padding: 0px;
  }

  .invoice {
    width: 100%;
    padding: 12px;
  }

  .desc_hide {
    display: block;
    min-width: 100% !important;
    margin-bottom: 10px;
    margin-top: 12px;
  }

  .form-head {
    display: block;
  }

  .bill-head {
    display: inline-block;
    font-size: 13px;
    text-align: left;
  }

  .full_ph {
    min-width: 100% !important;
  }

  span.bill-head.ph_hide {
    display: none;
  }

  .thead b {
    font-size: 12px;
    /* text-align: left; */
  }

  button.center_btn_ph {
    display: block;
    margin: 15px auto;
  }

  /* .status_ph {
    padding-left: 15px;
  } */

  .invoice-body {
    grid-gap: 10px;
  }

  .invoice-details_data div label {
    font-size: 12px;
  }

  .footer div p {
    font-size: 12px;
  }

  .invoice-details_data label {
    display: block;
  }

  .ph_view {
    min-width: 38% !important;
  }

  .flex_ph {
    display: flex;
  }

  .blank_ph {
    min-width: 1% !important;
  }

  h3.word_amount {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }

  .task_combine {
    gap: 10px;
  }

  .bank_data div label {
    font-size: 14px;
  }

  .invoice-detail p {
    font-size: 14px;
  }

  .invoice-details_data span {
    min-width: auto;
    font-size: 12px;
  }

  .invoice-details_data div {
    display: block;
  }

  .invoice-body.ph_bill {
    grid-template-columns: repeat(1, 1fr);
    display: block !important;
  }

  .bank_data.main_bank_ph div {
    display: flex;
    justify-content: left;
    font-size: 14px;
  }

  .bank_data.main_bank_ph label {
    display: block;
    min-width: 110px;
  }

  .task_desc {
    font-size: 14px;
  }

  .text-right p {
    font-size: 14px;
  }

  .main-footer .footer {
    display: block;
    padding-left: 5px;
    padding-top: 10px;
  }
  .main-footer-rs .footer {
    display: block;
    padding-left: 5px;
    padding-top: 10px;
  }

  .address {
    padding: 5px 17px;
    padding-bottom: 12px;
  }

  .middle {
    padding: 5px 17px;
  }

  .sign_mam {
    object-fit: contain !important;
    width: auto !important;
  }
}